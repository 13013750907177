// import App from "next/app";
import 'reflect-metadata';
import '../styles/globals.scss';

import App from 'next/app';
import Head from 'next/head';
import { createElement, createRef, Fragment } from 'react';
import { from } from 'rxjs';
import { delay, filter, mergeMap, retryWhen, take } from 'rxjs/operators';

import { Commons } from '../commons/commons';
import { FooterComponent } from '../components/footer/footer.component';
import HeaderComponent from '../components/header/header.component';
import { DIProvider } from '../context/ioc';
import { container, lazyInject } from '../context/module';
import { Logger } from '../models/logger';
import { GAService } from '../services/analytics.service';
import { ApiConfig } from '../services/api-config';
import { DOMStorage } from '../services/dom-storage.service';
import { EnvironmentService } from '../services/environment.service';
import { FileUploadRecoveryService } from '../services/file-upload-recovery.service';
import { FileUploadService } from '../services/file-upload.service';
import { LightHttp } from '../services/light-http.service';
import { LogService } from '../services/log.service';
import { LoginService } from '../services/login.service';
import { MetadataService } from '../services/metadata.service';
import { MotivesService } from '../services/motives.service';
import { ScriptLoadService } from '../services/script-load.service';
import { SettingsService } from '../services/settings.service';
import { UIContext } from '../services/ui-context';
import { WebSocketService } from '../services/websocket.service';
import { WindowService } from '../services/window.service';
import { appPropsToRouteProps, RouteProps } from '../types/route-types';
import { BrowserUtils } from '../ui-utils/browser-utils';
import { get, isEqual } from '../utils/lodash-min';
import { MotiveConfig, NetworkError } from '../utils/request.utils';
import { RouteUtils } from '../utils/route.utils';
import { StringUtils } from '../utils/string-utils';
import { WindowUtils } from '../utils/window.utils';
import ErrorPage from './_error';
import { SpinnerService } from '../services/spinner.service';
import SpinnerModal from '../components/ui/spinner/spinner.modal';
import { UserTraceService } from '../services/user-trace.service';
import Script from 'next/script';
// import Clarity from '@microsoft/clarity';

// if (BrowserUtils.hasWindow()) {
//     Clarity.init('qpfzjffr8a');
// }

interface MyAppState {
    fileSelectBtnClicked: boolean;
    classes: string;
}
export default class MyApp extends App<any, any, MyAppState> {
    private readonly logger = Logger.getLogger('MyApp');

    fileInputRef = createRef<HTMLInputElement>();

    /**Need this here just to initialize the logger */
    @lazyInject(LogService) private logService: LogService;
    @lazyInject(LoginService) private loginService: LoginService;
    @lazyInject(EnvironmentService) private environmentService: EnvironmentService;
    @lazyInject(WindowService) private windowService: WindowService;
    @lazyInject(MotivesService) private motivesService: MotivesService;
    @lazyInject(SettingsService) private settingsService: SettingsService;
    @lazyInject(WebSocketService) private webSocketService: WebSocketService;
    @lazyInject(DOMStorage) private domStorage: DOMStorage;
    @lazyInject(FileUploadService) private fileUploadService: FileUploadService;
    @lazyInject(FileUploadRecoveryService) private fileUploadRecoveryService: FileUploadRecoveryService;
    @lazyInject(UIContext) private uiContext: UIContext;
    @lazyInject(ApiConfig) private apiConfig: ApiConfig;
    @lazyInject(LightHttp) private lightHttp: LightHttp;
    @lazyInject(ScriptLoadService) private scriptLoadService: ScriptLoadService;
    @lazyInject(MetadataService) private metadataService: MetadataService;
    @lazyInject(GAService) private gaService: GAService;
    @lazyInject(SpinnerService) private spinnerService: SpinnerService;
    @lazyInject(UserTraceService) private userTraceService: UserTraceService;
    // @lazyInject(FileInputButtonService) private fileInputButtonService: FileInputButtonService;

    canShowMotives: boolean;
    sitename: string;
    siteDisplayName: string;
    effectiveMotives: MotiveConfig;

    downloadableFileCount = 0;

    // main.js version
    version = 1.023;
    // index.html version
    local_version = 0.000;
    // actual version from mongo
    remote_version = 0.000;

    shouldReload = false;

    performedVersionCheckAndAutoLogin = false;
    extensionInstalled = false;

    showExtension: boolean;
    helpMaximized = false;

    currentUrl: string | null = null;
    canGoOffline: boolean;

    routeProps: RouteProps;

    get isIOS() {
        return BrowserUtils.isIOS();
    }
    get isAndroid() {
        return BrowserUtils.isAndroid();
    }

    readonly state: Readonly<MyAppState> = { fileSelectBtnClicked: false, classes: `root-container` };
    constructor(public props) {
        super(props);
        this.environmentService.loadEnvironmentFromCLIData(props);
        // if (BrowserUtils.hasWindow()) {
        //     const wsId = this.webSocketService.getOrCreateStoredWSId()
        //     Clarity.identify(wsId);
        // }
        this.logService.initialize();
        if (props.pageProps.statusCode === 404) {
            return;
        }
        this.scriptLoadService.loadAdsense()
            .catch(() => {
                // console.log('Failed to load adsense.');
            });
        this.routeProps = appPropsToRouteProps(props);
        this.motivesService.initialMotives = get(this.routeProps, 'settings.motive');
        this.settingsService.initializeFromProps(this.routeProps);
        // this.metadataService.setMetaTags(this.routeProps.pageData);
        // this.recordCLS();
        this.init();
        const componentMountedTime = Date.now();
        this.windowService.onFileSelectBtnClicked.pipe(
            filter(evt => evt.dispatchedAt.getTime() > componentMountedTime),
        ).subscribe({
            next: () => {
                if (!this.state.fileSelectBtnClicked) {
                    this.setState({
                        fileSelectBtnClicked: true,
                    });
                }
            }
        });
        this.webSocketService.initWS();
    }

    componentDidMount(): void {
        this.userTraceService.trace("App component mounted");
        this.setState({
            classes: `root-container ${this.isIOS ? 'ios' : (this.isAndroid ? 'android' : '')}`
        });
    }

    componentDidUpdate() {
        // This is required since we disabled GA4 Enhanced measurement
        this.gaService.pageview(this.routeProps?.url);
    }

    private init() {
        const { Component, pageProps } = this.props;
        if (this.props.pageProps.statusCode === 404) {
            return;
        }
        if (!pageProps.url) {
            console.error('Page loaded without a url', pageProps);
            return;
        }

        this.sitename = this.environmentService.env.sitename;
        this.siteDisplayName = this.environmentService.env.siteDisplayName;

        this.motivesService.onEffectiveMotiveSettingsChange.subscribe(
            (motives) => {
                this.showExtension = isEqual(get(BrowserUtils.getBrowserInfo(), 'name'), 'chrome') && !BrowserUtils.isMobile() &&
                    motives && motives.showExtensionDownloadDialog;
                this.effectiveMotives = motives;
            },
            (error) => this.logger.error(error),
        );
        this.loginService.onUserDetailsChanged.subscribe(
            (accountDetails) => {
                this.handleAccountDetails(accountDetails);
            },
        );

        if (BrowserUtils.hasWindow()) {
            this.windowService.initialize().subscribe();
        }

        const currentPagePath = this.props.path;
        let isValidCapability = false;
        let featurePagePath = null;
        try {
            featurePagePath = currentPagePath.match(/[\-0-9a-zA-Z]+/)[0];
            // TODO disabled for preact
            // isValidCapability = this.capabilityServie.hasCapability(currentPagePath);
        } catch (e) {
            // suppressed
        }

        WindowUtils.runWhenIdeal(() => {
            this.fileUploadService.initialize();
        });

        WindowUtils.runWhenIdeal(() => {
            this.fileUploadRecoveryService.initialize();
        });

        if (BrowserUtils.hasWindow()) {
            this.setUpLoginService();
            // this.setUpVersionCheck();
            this.setUpSettingsService();
            this.setUpGoogleAnalytics();
        }

        WindowUtils.runWhenIdeal(() => {
            this.motivesService.initialize();
        });
        this.domStorage.setAppComponentReadyAndFreezeContentIfNeeded();
    }

    private handleAccountDetails(accountDetails) {
        if (accountDetails) {
            this.canShowMotives = this.windowService && this.windowService.mousedowned &&
                this.motivesService.motiveReady && this.settingsService.motivesEnabled &&
                !accountDetails.isPaidUser();
        }
        this.canGoOffline = this.loginService.canGoOffline;
    }

    private setUpSettingsService() {
        this.settingsService.initialize().subscribe({
            next: () => {
                // no-op
            },
            error: (e) => {
                this.logger.error(e);
            }
        })
    }

    private setUpLoginService(spinner: SpinnerModal = null) {
        this.initializeWSService$();
        this.loginService.login(null, null, null).subscribe({
            next: () => {
                if (spinner) {
                    this.spinnerService.hide(spinner);
                    spinner = null
                    this.uiContext.successToast("Successfully initialized services!")
                }
            },
            error: (err) => {
                if (spinner) {
                    this.spinnerService.hide(spinner);
                    spinner = null
                }

                if (NetworkError.is(err)) {
                    err.message = 'Unable to initialize services. Please connect and reload the page again!';
                }
                this.uiContext.handleError(
                    err,
                    {
                        confirmButtonText: "Retry",
                        confirmButtonCallback: async () => {
                            this.setUpLoginService(await this.spinnerService.show("Retrying..."))
                        }
                    }
                );
                this.logger.error(err);
            }
        });
    }

    initializeWSService$(wsDomains: string[] = []) {
        let init$ = this.windowService.userClicked;
        if (this.routeProps.url && this.routeProps.url.startsWith('/downloads')) {
            init$ = this.windowService.userEngaged;
        }
        init$.pipe(
            mergeMap(() => {
                return from(this.webSocketService.reinitialize(wsDomains));
            }),
        ).subscribe({
            next: () => {
                this.webSocketService.initialize();
            },
            error: () => {
                console.log('Failed to initialize WS');
            }
        });
    }

    private setUpVersionCheck() {
        // await this.scriptLoadService.loadAllScriptsIfNotLoaded();
        let versionLogged = false;
        const updateCheckFn = () => {
            // this.updateExtensionStatus(); // TODO PReact disabled
            this.windowService.userClicked.subscribe({
                next: () => {
                    this.lightHttp.get(this.apiConfig.getVersionCheckEndpoint()).pipe(
                        retryWhen(errors => errors.pipe(delay(1000), take(2))),
                    ).subscribe({
                        next: (data: any) => {
                            this.remote_version = data.web_version;
                            if (!versionLogged) {
                                this.logger.trace1({
                                    message: `HTML version: ${this.local_version}, JS version: ${this.version} and Remote version: ${this.remote_version}`,
                                });
                                versionLogged = true;
                            }
                            // this.updateShouldReload(); // TODO PReact disabled
                        },
                        error: (err) => {
                            this.logger.trace7({
                                message: 'Unable to get the web version',
                                data: err,
                            });
                        }
                    })
                }
            });
        };

        // Initially poll every second for first 20 seconds
        let interval = setInterval(updateCheckFn, 20000);
        setTimeout(() => {
            clearInterval(interval);
            // Fallback to every one minute
            interval = setInterval(updateCheckFn, 20000 * 60);
        }, 1000 * 20);
    }

    setUpGoogleAnalytics() {
        // Using Rx's built in `distinctUntilChanged ` feature to handle url change c/o @dloomb's answer
        if (BrowserUtils.hasWindow()) {
            let ga = this['ga'];
            try {
                ga = window && window['ga'];
            } catch (e) {
                this.logger.error(e);
            }
            if (ga) {
                ga('set', 'page', this.currentUrl);
            }
        }
    }

    getComponent(props: RouteProps) {
        if (!props || !props.url) {
            return ErrorPage;
        }
        const pathname = RouteUtils.pathTextOnly(props.url);
        if (BrowserUtils.hasWindow()) {
            const windowPath = RouteUtils.pathTextOnly(window.location.pathname);
            if (windowPath !== pathname) {
                // React migrate
                // return <NotFoundPage></NotFoundPage>
            }
        }
        this.loadRouteSpecificScripts(pathname);

        // switch (pathname) {
        //     case '':
        //         return <HomeComponent {...props}></HomeComponent>;
        //     case 'downloads':
        //         return <FileDownloadComponent {...props}></FileDownloadComponent>;
        //     default:
        //         const Comp = ROUTE_TO_COMPONENT_MAP[pathname];
        //         if (Comp) {
        //             return <Comp {...props}></Comp>;
        //         }
        //         return <NotFoundPage></NotFoundPage>
        // }
    }

    loadRouteSpecificScripts(pathname: string) {
        if (BrowserUtils.hasWindow() && pathname.endsWith('downloads')) {
            try {
                this.scriptLoadService.loadGoogleDriveSave();
            } catch (e) {
                console.error(e);
            }
            try {
                this.scriptLoadService.loadDropbox();
            } catch (e) {
                console.error(e);
            }
        }
    }

    getTitle(pageProps) {
        const titleData = (pageProps.seoHeaderTagsJson || []).find(el => el.elementType == 'title');
        return (titleData && titleData.innerText && titleData.innerText.trim()) || 'XConvert.com'
    }

    getCustomHeaders(pageProps: any) {
        if (pageProps.seoHeaderTagsJson) {
            return <Fragment>
                {
                    (pageProps.seoHeaderTagsJson as any[]).map((el, index) => {
                        const attributes = el.elementAttributes || {};
                        if (attributes.content) {
                            attributes.content = StringUtils.removeNewLineAndMultipleContigeousWhiteSpaces(attributes.content);
                        }
                        const reactElement = createElement(el.elementType, {
                            key: `header-tag-${index}`,
                            ...attributes,
                        }, StringUtils.removeNewLineAndMultipleContigeousWhiteSpaces(el.innerText) || undefined);
                        return reactElement;
                    })
                }
                {

                    (pageProps?.path?.match(/product/i) || pageProps?.path?.match(/portal/i)) && <Fragment>
                        <script src={'https://www.paypal.com/sdk/js?client-id=' + Commons.PAYPAL_CLIENT_ID + '&vault=true'}></script>
                        <script async defer src='https://js.stripe.com/v3/'></script>
                    </Fragment>
                }
            </Fragment>
        }
        return <link href="https://www.google.com" />;
    }

    render() {
        const { Component, pageProps } = this.props;
        if (this.props.pageProps.statusCode === 404) {
            return <Component {...pageProps} />;
        }
        this.routeProps = appPropsToRouteProps(this.props);
        this.getComponent(this.routeProps)
        return <>
            <Head>
                <meta name="viewport" content="width=device-width,initial-scale=1" />
                {/* <title>{this.getTitle(pageProps)}</title> */}
                {this.getCustomHeaders(pageProps)}
            </Head>
            <DIProvider container={container}>
                {/* <input key={'g-file-input'}
                    ref={el => this.fileInputButtonService.registerFileInputRef(el)}
                    type="file"
                    multiple={!this.props.singleFileSelect} hidden={true} accept={this.props.acceptedFileTypes}
                // onChange={($ev) => this.processFileSelectedEvent($ev as any)} 
                /> */}
                <div className={`${this.state.classes}`}>
                    <Fragment>
                        {pageProps.url && <HeaderComponent key="header" {...pageProps} />}
                        <Component {...pageProps} />
                        {pageProps.url && <FooterComponent
                            key="footer"
                            {...pageProps}
                            fileSelectBtnClicked={this.state.fileSelectBtnClicked}
                        ></FooterComponent>}
                    </Fragment>
                </div>
            </DIProvider>

            <Script defer type="text/javascript" src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js"></Script>
            <Script defer type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.1/js/bootstrap.bundle.min.js"></Script>
            <Script async defer type="text/javascript" src="https://www.dropbox.com/static/api/2/dropins.js" id="dropboxjs" data-app-key="crbrplpove29sb2"></Script>
            <Script defer type="text/javascript" src='https://apis.google.com/js/platform.js'></Script>
            <Script defer type="text/javascript" src='https://apis.google.com/js/api.js'></Script>
            <Script defer async type="text/javascript" src='/js/lib/html2canvas.min.js'></Script>
            <Script defer type="text/javascript" src='/js/lib/load-image.all.min.js'></Script>
            <Script defer type="text/javascript" src='/js/lib/canvas-to-blob.min.js'></Script>
            <Script defer async type="text/javascript" src='/js/lib/download.js'></Script>
            
            {/* GLFX moved to photo editor/image resizer
            <Script defer async type="text/javascript" src='/js/lib/glfx.6.min.js'></Script> */}
            <Script defer async type="text/javascript" src='/js/lib/libwebp-0.1.min.js'></Script>
            <Script defer async type="text/javascript" src='/js/lib/UTIF.min.min.js'></Script>
            <Script defer async type="text/javascript" src='/js/lib/marked.min.js'></Script>
            <Script defer async type="text/javascript" src='/js/lib/canvastotiff.min.js'></Script>
            <Script defer async type="text/javascript" src='/js/lib/hammer.min.js'></Script>
            <Script defer async src="https://sak.userreport.com/cac4432f794143b9a481/launcher.js" id="userreport-launcher-script"></Script>
        </>
    }
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps }
// }
