import { UploadInProgressFile } from "../models/upload-in-progress-file";
import { FileUtils } from "./file-utils";

/**
 * @see UPLOADED_FILE_TARGET_SIZE_BY_DIMENSION_FN on global-functions.ts for method description
 */
export const UPLOADED_FILE_TARGET_SIZE_BY_DIMENSION_FN = (selectedPercentage: number, uploadInProgressFiles: UploadInProgressFile<any>[]): string => {
    if (selectedPercentage == 0 || !uploadInProgressFiles || uploadInProgressFiles.length == 0) {
        return ''
    }
    try {
        const value = Number(selectedPercentage);
        const valueReduction = 100 - value;
        const percentageReduction = 100 - Math.pow(value / 100, 2) * 100;

        return `<ul>` + uploadInProgressFiles.map(f => {
            const file = f.getFile();

            const originalSize = file.size;
            const idealReducedSize = originalSize * (value / 100) ** 2; // Calculate ideal reduced size
            const newSizeBest = FileUtils.toReadableBytes(idealReducedSize, 'quantity');
            const filename = file.name;
            const filesizeSource = FileUtils.toReadableBytes(originalSize, 'quantity');
            return `<li><b>${filename}</b> will reduce from ${filesizeSource} to ${newSizeBest}</li>`;
        }).join("")
        + '</ul>';
    } catch (e) {
        console.error(e);
    }
    return '';
};

/**
 * @see UPLOADED_FILE_TARGET_SIZE_FN on global-functions.ts for method description
 */
export const UPLOADED_FILE_TARGET_SIZE_FN = (selectedPercentage: number, uploadInProgressFiles: UploadInProgressFile<any>[]): string => {
    if (selectedPercentage == 0 || !uploadInProgressFiles || uploadInProgressFiles.length == 0) {
        return ''
    }
    try {
        return `Target Size: ${selectedPercentage}% of Original <br/>` + uploadInProgressFiles.map(f => {
            const file = f.getFile();
            const filename = file.name;
            const filesizeSource = FileUtils.toReadableBytes(file.size, 'quantity');
            const filesizeTarget = FileUtils.toReadableBytes(file.size * selectedPercentage / 100, 'quantity');
            return `- <b>${filename}:</b> Will reduce from ${filesizeSource} to ${filesizeTarget} (${selectedPercentage}% of original)`;
        }).join("<br/>");
    } catch (e) {
        console.error(e);
    }
    return '';
};

export const TIME_VALIDATE_FN = (value: string, type: string): string => {
    if (type === 'seconds') {
        if (!value.match(/^\d+(\.\d{1,3}){0,1}$/)) {
            return `Accepted value examples i.e 23 (for 23 seconds) \
            or 23.453 for (23 seconds and 453 milliseconds)`;
        }
    }
    if (type === 'formated_time') {
        if (!value.match(/^(\d{1,2}:){0,2}(\d{1,2})(\.\d{1,3}){0,1}$/)) {
            return `Accepted value examples i.e 23 (for 23 seconds) \
            or 23.453 for (23 seconds and 453 milliseconds)
            or 12:23.453 for (12 minutes 23 seconds and 453 milliseconds)
            or 3:1:23.453 for (3 hours 1 minute 23 seconds and 453 milliseconds)`;
        }
    }
    return null;
};

export const OUTPUT_EXT_FROM_OPTIONS_FN = (allOptions: any) => {
    if (allOptions.formCategories) {
        const outputExtensionOptions = allOptions.formCategories
            .find(f => f.category === 'outputExt');
        const outputExtensionOption = outputExtensionOptions && outputExtensionOptions.formGroups[0];
        return outputExtensionOption ? outputExtensionOption.selections.find(a => a.selected).formValue : null;
    }
    return null;
};


export const OUTPUT_VIDEO_ENCODER_FROM_OPTIONS_FN = (allOptions: any, categoryName: string, formGroupName: string) => {
    const category = allOptions.formCategories.find(cat => cat.category === categoryName);

    if (category) {
        const formGroup = category.formGroups.find(group => group.formGroupName === formGroupName);

        if (formGroup) {
            const selectedItem = formGroup.selections.find(selection => selection.selected);

            if (selectedItem) {
                return selectedItem.formValue;
            }

            // Check nested options recursively:
            for (const selection of formGroup.selections) {
                if (selection.formChildren) {
                    for (const child of selection.formChildren) {
                        if (child.formGroup && child.formGroup.selections) {
                            const nestedSelectedItem = child.formGroup.selections.find(nestedSelection => nestedSelection.selected);
                            if (nestedSelectedItem) {
                                return nestedSelectedItem.formValue;
                            }
                        }
                    }
                }
            }
        }
    }

    return null; // Return null if no item is found
}