import { ToTypes } from '../types/types';
import { FilenameUtils } from './filename-utils';
import { includes } from './lodash-min';

declare function loadImage(file: File, callback?: (canvas: HTMLCanvasElement) => any, options?: any);
declare function dataURLtoBlob(dataUrl);

export class FileUtils {
    static IMAGE_EXTENSIONS = ['PNG', 'JPG', 'JPEG', 'TIFF', 'TIF', 'ANI', 'ANIM', 'APNG', 'ART', 'BMP', 'BPG', 'BSAVE',
        'CAL', 'CIN', 'CPC', 'CPT', 'DDS', 'DPX', 'ECW', 'EXR', 'FITS', 'FLIC', 'FLIF', 'FPX', 'GIF', 'HDRI', 'HEVC', 'ICER', 'ICNS', 'ICO',
        'CUR', 'ICS', 'ILBM', 'JBIG', 'JBIG2', 'JNG', 'JPEG', 'JPEG-LS', 'JPEG', '2000', 'JPEG', 'XR', 'JPEG',
        'XT', 'JPEG-HDR', 'KRA', 'MNG',
        'MIFF', 'NRRD', 'ORA', 'PAM', 'PBM', 'PGM', 'PPM', 'PNM', 'PCX', 'PGF', 'PICTOR', 'PNG', 'PSD', 'PSB', 'PSP', 'QTVR', 'RAS', 'RGBE',
        'LOGLUV', 'TIFF', 'SGI', 'TGA', 'TIFF', 'UFP', 'WBMP', 'WEBP', 'XBM', 'XCF', 'XPM', 'XWD', 'CIFF', 'DNG', 'AI', 'CDR', 'CGM', 'DXF',
        'EVA', 'EMF', 'GERBER', 'HVIF', 'IGES', 'PGML', 'SVG', 'VML', 'WMF', 'XAR', 'CDF', 'DJVU', 'EPS',
        'PICT', 'SWF', 'XAML', 'NEF', 'HEIC', 'CR2'];
    static AUDIO_EXTENSIONS = ['3GP', 'AA', 'AAC', 'AAX', 'ACT', 'AIFF', 'AMR', 'APE', 'AU', 'AWB', 'DCT', 'DSS', 'DVF',
        'FLAC', 'GSM', 'IKLAX', 'IVS', 'M4A', 'M4B', 'M4P', 'MMF', 'MP3', 'MPC', 'MSV',
        'NSF', 'OGG', 'OGA', 'MOGG', 'OPUS', 'RA', 'RM', 'RAW',
        'SLN', 'TTA', 'VOX', 'WAV', 'WMA', 'WV', 'WEBM', '8SVX'];
    static VIDEO_EXTENSIONS = ['WEBM', 'MKV', 'FLV', 'FLV', 'VOB', 'OGV', 'OGG', 'DRC', 'GIF', 'GIFV', 'MNG', 'AVI',
        'MTS', 'M2TS', 'MOV', 'QT', 'WMV', 'YUV', 'RM', 'RMVB', 'ASF', 'AMV', 'MP4', 'M4P', 'M4V', 'MPG', 'MP3',
        'MPEG', 'MPE', 'MPV', 'MPG',
        'MPEG', 'M2V', 'M4V', 'SVI', '3GP', '3G2', 'MXF', 'ROQ', 'NSV', 'FLV', 'F4V', 'F4P', 'F4A', 'F4B'];
    static MS_OFFICE_EXTENSION = ['PPTX', 'PPTM', 'POTX', 'POTM', 'PPAM', 'PPSX', 'PPSM', 'PPT', 'POT', 'PPS', 'SLDX',
        'SLDM', 'XLS', 'XLT', 'XLM', 'XLSX', 'XLSM', 'XLTX', 'XLTM', 'DOC', 'DOT', 'WBK', 'DOCX', 'DOCM', 'DOTX', 'DOTM', 'DOCB'];
    static PDF_EXTENSION = ['PDF'];

    static PS_EXTENSION = ['PS'];

    static ALL_EXTENSIONS = [
        ...FileUtils.IMAGE_EXTENSIONS,
        ...FileUtils.AUDIO_EXTENSIONS,
        ...FileUtils.VIDEO_EXTENSIONS,
        ...FileUtils.MS_OFFICE_EXTENSION,
        ...FileUtils.PDF_EXTENSION,
        ...FileUtils.PS_EXTENSION,
    ];

    static DISALLOWED_EXTENSTIONS = new Set([
        "ADE", "ADP", "APK", "APPX", "APPXBUNDLE", "BAT", "CAB", "CHM", "CMD", "COM", "CPL", "DIAGCAB", "DIAGCFG", 
        "DIAGPACK", "DLL", "DMG", "EX", "EX_", "EXE", "HTA", "IMG", "INS", "ISO", "ISP", "JAR", "JNLP", "JS", "JSE", 
        "LIB", "LNK", "MDE", "MSC", "MSI", "MSIX", "MSIXBUNDLE", "MSP", "MST", "NSH", "PIF", "PS1", "SCR", "SCT", 
        "SHB", "SYS", "VB", "VBE", "VBS", "VHD", "VXD", "WSC", "WSF", "WSH", "XLL", "BAK", "BCK", "BKP", "CFG", 
        "CONF", "CONFIG", "INI", "LOG", "OLD", "SAV", "SAVE", "TEMP", "TMP", "BIN", "CGI", "SHTM", "SHTML", "STM", 
        "CER", "CRT", "DER", "KEY", "P12", "P7B", "P7C", "PEM", "PFX", "DAT", "EML", "HTR", "HTW", "IDA", "IDC", "IDQ", 
        "NWS", "POL", "PRINTER", "REG", "WMZ"
    ])

    static THUMBNAIL_SUPPORTED_EXTENSIONS = [
        ...FileUtils.IMAGE_EXTENSIONS,
        ...FileUtils.PDF_EXTENSION,
        ...FileUtils.VIDEO_EXTENSIONS,
    ];

    public static isVideoToType(toType: string) {
        toType = toType.toLowerCase();
        return toType === ToTypes.VIDEO || includes(FileUtils.VIDEO_EXTENSIONS.map(t => t.toLowerCase()), toType);
    }

    public static isAudioToType(toType: string) {
        toType = toType.toLowerCase();
        return toType === ToTypes.AUDIO || includes(FileUtils.AUDIO_EXTENSIONS.map(t => t.toLowerCase()), toType);
    }

    public static isImageToType(toType: string) {
        toType = toType.toLowerCase();
        return toType === ToTypes.IMAGE || includes(FileUtils.IMAGE_EXTENSIONS.map(t => t.toLowerCase()), toType);
    }

    public static isVideoFromType(fromTypes: string[]) {
        const supportedFromTypes = [ToTypes.VIDEO, ...FileUtils.VIDEO_EXTENSIONS].map(t => t.toLowerCase());
        fromTypes = fromTypes.map(t => t.toLowerCase());
        return fromTypes.some(fromType => includes(supportedFromTypes, fromType));
    }

    public static isAudioFromType(fromTypes: string[]) {
        const supportedFromTypes = [ToTypes.AUDIO, ...FileUtils.AUDIO_EXTENSIONS].map(t => t.toLowerCase());
        fromTypes = fromTypes.map(t => t.toLowerCase());
        return fromTypes.some(fromType => includes(supportedFromTypes, fromType));
    }

    public static isImageFromType(fromTypes: string[]) {
        const supportedFromTypes = [ToTypes.IMAGE, ...FileUtils.IMAGE_EXTENSIONS].map(t => t.toLowerCase());
        fromTypes = fromTypes.map(t => t.toLowerCase());
        return fromTypes.some(fromType => includes(supportedFromTypes, fromType));
    }

    public static fileToJpgImage(file: File): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            loadImage(file, (canvas: HTMLCanvasElement) => {
                const image = new Image();
                image.onload = () => {
                    resolve(image);
                };
                image.src = URL.createObjectURL(dataURLtoBlob(canvas.toDataURL('image/jpg', 100)));
            }, {
                canvas: true,
            });
        });
    }

    public static fileToTransparentPngImage(file: File): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            loadImage(file, (canvas: HTMLCanvasElement) => {
                const image = new Image();
                image.onload = () => {
                    resolve(image);
                };
                image.src = URL.createObjectURL(dataURLtoBlob(canvas.toDataURL('image/png', 100)));
            }, {
                canvas: true,
            });
        });
    }

    public static getExtensionList(filenames: string[]): string[] {
        const array: string[] = [];
        filenames.forEach(fName => {
            array.push(fName.substring(fName.indexOf('.') + 1));
        });
        return array;
    }

    public static isExtensionSupported(filename: string) {
        return FileUtils.ALL_EXTENSIONS.indexOf(FilenameUtils.getExtension(filename).toLocaleUpperCase()) !== -1;
    }

    public static isExtensionAllowed(filename: string): boolean {
        return FileUtils.DISALLOWED_EXTENSTIONS.has(FilenameUtils.getExtension(filename).toLocaleUpperCase());
    }

    public static isThumbnailSupported(filename: string) {
        const extension = FilenameUtils.getExtension(filename).toLocaleUpperCase();
        return FileUtils.THUMBNAIL_SUPPORTED_EXTENSIONS.indexOf(extension) !== -1;
    }

    public static toReadableBytes(value: number, unitType: 'rate' | 'quantity') {
        const unitBase = unitType =='quantity' ? 'B' : 'Bps';
        const n = parseInt(value + "", 10);
        if (n > 1000000000) {
            return parseFloat(`${n / 1000000000}`).toFixed(2) + ` G${unitBase}`;
        }
        if (n > 1000000) {
            return parseFloat(`${n / 1000000}`).toFixed(2) + ` M${unitBase}`;
        }
        if (n > 1000) {
            return parseFloat(`${n / 1000}`).toFixed(2) + ` K${unitBase}`;
        }
        return n + (unitType =='quantity' ? ' Bytes': 'Bps');
    }

    public static toReadableBits(bits, unitType: 'rate' | 'quantity') {
        const unitBase = unitType =='quantity' ? 'b' : 'bps';
        const n = parseInt(bits + "", 10);
        if (n > 1000000000) {
            return parseFloat(`${n / 1000000000}`).toFixed(2) + ` G${unitBase}`;
        }
        if (n > 1000000) {
            return parseFloat(`${n / 1000000}`).toFixed(2) + ` M${unitBase}`;
        }
        if (n > 1000) {
            return parseFloat(`${n / 1000}`).toFixed(2) + ` K${unitBase}`;
        }
        return n + unitType =='quantity' ? ' bits': 'bps';
    }
}
