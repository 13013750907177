import { Component, createRef } from 'react';

import ColorUtils from '../../../utils/color.utils';
import { HtmlEntityTypes } from '../../../utils/icon.utils';
import Modal from '../modal/modal.component';
import styles from './alert-dialog.component.module.scss';

const defaultAlertOptions = {
    type: 'success',
    confirmButtonText: 'Ok',
    confirmButtonClass: 'btn btn-primary me-2',
    cancelButtonText: 'Dismiss',
    cancelButtonClass: 'btn btn-secondary',
}

interface AlertDialogProps {
    title: string,
    text?: string,
    html?: string,
    type?: 'danger' | 'warning' | 'info' | 'success',
    confirmButtonText?: string,
    confirmButtonClass?: string,
    confirmButtonCallback?: () => void;
    showCancelButton?: boolean,
    cancelButtonText?: string,
    cancelButtonClass?: string,
    cancelButtonCallback?: () => void;
    input?: 'email' | 'number' | 'text',
    inputPlaceholder?: string,
    inputValidator?: (value) => boolean,
}

export default class AlertDialog extends Component<AlertDialogProps> {
    modalRef = createRef<Modal>();

    public static create(props: AlertDialogProps) {
        const initialState = props;
        Modal.createModal(AlertDialog, initialState);
    }

    constructor(props: AlertDialogProps) {
        super(props);

    }

    getOptionsFromProps(props: AlertDialogProps): AlertDialogProps {
        const mergedOptions = Object.assign({}, defaultAlertOptions, props);
        return mergedOptions;
    }

    getHTMLEntityForType(type: 'danger' | 'warning' | 'info' | 'success') {
        switch (type) {
            case 'danger':
                return HtmlEntityTypes.CROSS;
            case 'warning':
                return HtmlEntityTypes.WARNING;
            case 'info':
                return HtmlEntityTypes.INFO;
            default:
                return HtmlEntityTypes.CHECK;
        }
    }

    getIconTextColorFromType(type: 'danger' | 'warning' | 'info' | 'success') {
        if (type) {
            return `text-${type}`;
        }
        return 'text-primary';
    }

    getMainIcon(type: 'danger' | 'warning' | 'info' | 'success') {
        return <div className={`${styles['alert-icon']}`} style={{ borderColor: `${ColorUtils.getColor(type)}` }}>
            {
                <span className={`${this.getIconTextColorFromType(type)}`} dangerouslySetInnerHTML={{ __html: this.getHTMLEntityForType(type) }}></span>
            }
        </div>
    }

    getOkButtonBgColorClassFromType(type: 'danger' | 'warning' | 'info' | 'success') {
        if (type === 'danger') {
            return 'btn-danger';
        }
        return 'btn-primary';
    }

    dismissClose() {
        if (this.props.cancelButtonCallback) {
            this.props.cancelButtonCallback();
        }
        this.modalRef.current.unmount();
    }

    confirmClose() {
        if (this.props.confirmButtonCallback) {
            this.props.confirmButtonCallback();
        }
        this.modalRef.current.unmount();
    }

    render() {
        const options = this.getOptionsFromProps(this.props);
        return <Modal ref={this.modalRef} disableCloseButton={true} modalClass={'alert-dialog'}>
            <div data-modalbody>
                <h2>
                    {
                        this.getMainIcon(options.type)
                    }
                </h2>
                <h2>{this.props.title}</h2>
                {
                    this.props.text && <p>{this.props.text}</p>
                }
                {
                    this.props.html && <p dangerouslySetInnerHTML={{ __html: this.props.html }}></p>
                }
            </div>
            <div data-modalfooter>
                <button key={'confirmation-button'} onClick={() => this.confirmClose()}
                    className={`x-btn x-btn-sm x-btn-gray${this.getOkButtonBgColorClassFromType(options.type)}`}>
                    {options.confirmButtonText || 'Ok'}
                </button>
                {
                    options.showCancelButton && <button className="x-btn x-btn-sm x-btn-gray btn-secondary ms-2" onClick={() => this.dismissClose()}>
                        {options.cancelButtonText || 'Dismiss'}
                    </button>
                }
            </div>
        </Modal>
    }
}