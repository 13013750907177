import { Component, createRef } from 'react';

import styles from './toggle-switch.component.module.scss';

interface ToggleSwitchComponentProps {
    id: string;
    className: string;
    checked: boolean;
    shouldToggle?: (nextValue) => Promise<boolean>;
    onChange: (state: boolean) => void;
}

interface ToggleSwitchComponentState {
    checked: boolean;
}

export class ToggleSwitchComponent extends Component<ToggleSwitchComponentProps, ToggleSwitchComponentState> {
    ref = createRef<HTMLInputElement>();
    readonly state: Readonly<ToggleSwitchComponentState> = {
        checked: this.props.checked,
    };
    constructor(props) {
        super(props);
    }

    toggleHandlerClicked() {
        const currentState = this.state.checked;
        if (this.props.shouldToggle) {
            this.props.shouldToggle(!currentState).then(
                (result) => {
                    if (result) {
                        this.setState({ checked: !currentState });
                        this.props.onChange(!currentState);
                    }
                    this.forceUpdate();
                },
                (err) => {
                    console.error(err);
                }
            );
        } else {
            this.setState({ checked: !currentState });
            this.props.onChange(!currentState);
            this.forceUpdate();
        }
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.checked !== this.state.checked;
    }

    static getDerivedStateFromProps(props, state) {
        return { checked: props.checked }
    }

    render() {
        return <label className={styles['switch']}>
            <span className={`${styles['slider']} ${styles['round']} ${this.state.checked ? styles['checked'] : ''}`}
                onClick={() => this.toggleHandlerClicked()}></span>
        </label>
    }
}